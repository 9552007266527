import { solidstate } from ":shared/utils/state-manager";
import { For, Match, Show, Switch, createMemo, splitProps } from "solid-js";

import { global } from ":global";
import { EmptyTable } from "./components/empty-table";
import { Table } from "./components/table";

import { getRequests } from "./apis/get-requests";
import { Loader2 } from ":src/components/loaders";
import { DropDown, SearchTool } from ":src/components/form";
import { updateRequest } from "./apis/update-request";
export default function OnSiteSupport() {
  const $tickets = solidstate.createAsync(getRequests);
  const $filters_options = createMemo(() => {
    const issue_types = {} as Record<string, { id: number; value: string }>;
    const status = {} as typeof issue_types;
    $tickets.value?.forEach((item, index) => {
      issue_types[item.issue_type] = { id: index, value: item.issue_type };
      status[item.status] = { id: index, value: item.status };
    });
    return {
      issue_type: Object.values(issue_types),
      status: Object.values(status),
    };
  });
  const $applied_filter = solidstate.create({} as { search?: string; issue_type?: string; status?: string });
  return (
    <section class="flex flex-col w-full h-full overflow-y-hidden overflow-x-hidden md:(px-5 py-3) <md:(px-3 py-2)">
      <h1 class="text-1.1rem  whitespace-nowrap  <md:text-1rem">On-Site Support Tickets</h1>
      {/* title and tabs */}
      <div class="flex flex-row   min-w-full justify-between py-2 md:(space-x-2) <md:(!flex-col flex-wrap space-y-2) ">
        <div class="flex w-full">
          <SearchTool onSearch={(value) => $applied_filter.set((s) => ({ ...s, search: value }))} />
        </div>
        <div class=" w-full md:w-[60%]! flex flex-row gap-3 justify-center items-center">
          <p class="text-black text-[18px] min-w-fit">Filter by</p>
          {/* <DropDown label="All Tracks" options={CategoryList} onValueChoose={handleFilteredData} /> */}
          <DropDown
            //
            label="Status"
            options={$filters_options()?.status}
            onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, status: value }))}
          />
          <DropDown
            //
            label="Issue Type"
            options={$filters_options()?.issue_type}
            onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, issue_type: value }))}
          />
        </div>
      </div>
      {/* list -- whatever it is */}
      <Switch>
        <Match when={$tickets.state.loading}>
          <Loader2 msg="Loading Support Requests..." />
        </Match>
        <Match when={$tickets.state.failed}>error occured while loading memebres {$tickets.error}</Match>
        <Match when={$tickets.state.success}>
          <Table
            // class="debug$"
            rows={$tickets.value}
            fallback={<EmptyTable msg="You do not have any requests in your inbox " />}
            triggers={{
              onRowClicked(item) {
                global.store.actions.popop.pushModal({
                  title: `${item.requester} | ${item.table_number}`,
                  // startExpanded: true,
                  element(action) {
                    return (
                      <section>
                        <span>{item.description}</span>
                      </section>
                    );
                  },
                });
              },
            }}
            events={{
              isActiveRow(item) {
                return item.status === "solved";
              },
              isHidden(item) {
                if (
                  $applied_filter.value.search &&
                  !item.requester.toLowerCase().includes($applied_filter.value.search)
                ) {
                  return true;
                }
                if ($applied_filter.value.status && item.status !== $applied_filter.value.status) {
                  return true;
                }
                if ($applied_filter.value.issue_type && item.issue_type !== $applied_filter.value.issue_type) {
                  return true;
                }
                return false;
              },
            }}
            classes={{
              desktop: {
                headersContainer: "bg#bg-default  font-semibold text-0.77rem rounded-t-6 ",
                singleHeader: "h-3.5rem ",
                singleRowContainer:
                  " overflow-y-hidden  min-h-3rem text-0.75rem border-b-2 border-solid border-gray-100 cursor-pointer",
                singleRowContainerActive: " border-black bg#a text-white",
                singleRow: "",
              },
              phone: {
                cardsContainer: "space-y-5 !h-full",
                singleCardContainer:
                  "shadow-md w-full !min-h-12rem py-2 border-2 border#bg-default border-solid rounded-xl !justify-between",
                singleCardContainerActive: " border-black bg#a !text-white children:(!text-white)",
                allCardsRows: "!w-90% !justify-betwen py-1.5",
                allCardsRowsActive: "children:(text-white)",
                singleCardHeader: "!text-0.5rem text-gray w-full",
                singleCardValue: "!w-fit !text-0.45rem",
              },
            }}
            columns={{
              Requester: {
                desktop: {
                  width: "25%",
                  // icon: <IconTableUser class="w-24px" />,
                  class: "pl-6 flex-[0.7]",
                },
                phone: {
                  cardHeaderClass: "!hidden !max-w-0 ",
                  class: "border-b-2 border-solid border-gray-100",
                },
                key: (r, helper) => {
                  return (
                    <div class="flex flex-row space-x-2">
                      {/* <img
                        class="w-80px h-80px <md:(w-50px h-50px) rounded-full object-cover"
                        // src={r.image ?? IconNoProfileImage}
                        alt={"profile_image"}
                      /> */}
                      <p class="flex flex-col ">
                        <span class="font-bold text-0.8rem <md:text-0.6rem">{`${r.requester ?? "Unknown Name"}`}</span>
                        {/* <span>{`${r.reference ?? "#000000"}`}</span> */}
                      </p>
                    </div>
                  );
                },
              },
              Mobile: {
                desktop: {
                  width: "20%",
                  // class: "flex-[0.5]",
                },
                phone: {},
                key: "mobile",
              },
              "Table Number": {
                desktop: {
                  width: "10%",
                  // class: "flex-[0.5]",
                },
                phone: {},
                key: "table_number",
              },
              "Issue Type": {
                desktop: {
                  width: "20%",
                  // class: "flex-[0.5]",
                },
                phone: {},
                key: "issue_type",
              },
              Status: {
                desktop: {
                  width: "10%",
                },
                phone: {},
                key: "status",
              },
              Action: {
                disableOnRowClick: true,
                desktop: {
                  width: "10%",
                  // headerClass: "-ml-1",
                  // class: "flex-[0.5]",
                },
                key: (r, helpers, index) => {
                  if (r.status === "solved") {
                    return null;
                  }
                  return (
                    <div class="flex flex-row space-x-2">
                      <span
                        class="text-green underline cursor-pointer"
                        onclick={() => {
                          global.store.actions.popop.pushConfirmDialog({
                            type: "warn",
                            message: `Are you sure this ticket is resolved for ${r.requester}?!`,
                            events: {
                              async onConfirmed() {
                                return updateRequest(r).then((data) => {
                                  global.store.actions.alert.pushToast({
                                    type: "success",
                                    message: `Ticket resolved, thank you champ!`,
                                  });
                                  $tickets.set((s) => {
                                    const tickets = [...s];
                                    tickets[index()] = { ...r, status: "solved" };
                                    return tickets;
                                  });
                                });
                              },
                            },
                          });
                        }}
                      >
                        Resolved
                      </span>
                    </div>
                  );
                },
              },
            }}
          />
        </Match>
      </Switch>
    </section>
  );
}
